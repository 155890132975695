import React, {Component} from "react";
import {
	Box,
	Backdrop,
	Typography, CircularProgress
} from "@material-ui/core";
import {
	Page
} from "../../../components";
import {
	Form
} from "./components";
import axios from "../../../plugins/axios";
import urls from "../../../constant/urls";
import {sendMessages} from "../../../common/messages";
import {formatPhoneNumber} from "../../../helpers/format";

class UserEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userGroups: [],
			form: {
				username: '',
				full_name: '',
				email: '',
				phone: '',
				password: '',
				allowed_ips: "",
				roles: [],
				status: 0,
				send_notifications: false,
				enable_2fa: false,
				additional_roles: {},
			},

			isShowBackdrop: true,
			isUserAdmin: Boolean(props?.app?.user?.role === 'admin')
		};

		this.userId = props.match.params.id;
		this.refFormik = React.createRef();
	}

	// инициализация страницы
	componentDidMount = async () => {
		await this.getUserGrups();
		this.initForm();
	}

	// заполнение данных пользователя в форму из бека
	initForm = () => {
		axios('get', `${urls["user-get"]}/${this.userId}`).then((response) => {
			const data = response.data;

			const form = {
				username: data.username,
				full_name: data.full_name,
				email: data.email,
				phone: Boolean(data.phone) ? formatPhoneNumber(data.phone) : "",
				allowed_ips: data.allowed_ips.join(','),
				role: data.role || '',
				group_id: data.group_id,
				status: data.status,
				send_notifications: data.send_notifications,
				enable_2fa: data.enable_2fa,
				additional_roles: data?.additional_roles || {}
			};

			this.setState({
				form,
				isShowBackdrop: false
			});

			this.refFormik.current.setValues(form);
		})
	}
	getUserGrups = async () => {
		const res = await axios('post', '/user/group/get-list', {
			limit: 9999
		}).then((res) => {
			return res?.data || []
		}).catch(() => {
			return []
		});
		this.setState({
			userGroups: res
		});
	}

	// фунция изменения пользователя
	onEditUser = (form) => {
		const body = this.getBody(form);

		axios('post', `${urls["user-edit"]}/${this.userId}`, body).then((response) => {
			sendMessages({
				message: "Пользователь успешно изменен",
				type: "success"
			});

			this.props.history.goBack();
		}).catch((error) => {
			sendMessages({
				message: error?.response?.data?.details || "Ошибка сервера",
				type: "danger"
			});
		});
	}
	getBody = (form) => {
		let body = {};

		Object.keys(form).map((key) => {
			let value = form[key];

			if (key === "status") {
				value = !!value ? 1 : 0
			}
			if (key === "send_notifications" || key === "enable_2fa") {
				value = Boolean(value)
			}
			if (key === "roles") {
				value = value.join(';');
			}
			if (value && key === "phone") {
				value = `+${value.replace(/[^\d]/g, '')}`;
			}
			if (key === "allowed_ips") {
				value = (value || "").split(",").map((address) => address.split(" ").join("")).join(",")
			}

			if (
				value ||
				key === "role" ||
				key === "phone" ||
				key === "status" ||
				key === "enable_2fa" ||
				key === "send_notifications"
			) {
				body[key] = value;
			}
		});

		return body
	}

	// функция изменения формы
	onChangeForm = (form) => {
		this.refFormik.current.setValues(form);
		this.setState({form});
	}

	render() {
		const {form, userGroups, isUserAdmin, isShowBackdrop} = this.state;

		return (
			<Page>

				<Box maxWidth={1200} margin="0 auto">
					<Box mb={4}>
						<Typography variant="h1">Редактирование пользователя</Typography>
					</Box>

					<Box py={4} borderRadius={4} boxShadow={1} bgcolor="white">

						<Form
							innerRef={this.refFormik}
							initialValues={form}
							userGroups={userGroups}
              isUserAdmin={isUserAdmin}

							onChange={this.onChangeForm}
							onSubmit={this.onEditUser}
						/>

					</Box>
				</Box>


				<Backdrop open={this.state.isShowBackdrop} invisible={this.state.isShowBackdrop}>
					<CircularProgress size={80} style={{color: 'white'}}/>
				</Backdrop>

			</Page>
		)
	}
}

export default UserEdit
