import React, {Component} from "react";
import {
    Box,
    Grid,
    Button,
    Tooltip,
    Typography, Backdrop, CircularProgress
} from "@material-ui/core";
import {
    Page,
    DialogConfirmation, WithoutAccess
} from "../../../components";
import {getPageFromCount} from "../../../common/pagination";
import {
    Filter,
    Table
} from "./components";
import axios from "../../../plugins/axios";
import urls from "../../../constant/urls";
import {sendMessages} from "../../../common/messages";

const initialFilter = {
    email: "",
    username: "",
    full_name: "",
    roles: [],

    page: 1,
    limit: 20
};

class UsersList extends Component {
    constructor(props) {
        super(props);

        const roles = {[props.app.user.role]: true};
        const isPermissions = roles.admin || roles.group_admin || roles['user.base'];

        this.state = {
            rows: [],

            filter: {...initialFilter},
            pagination: {
                page: 1
            },
            deleteUser: null,

            isLoading: true,
            isShowBackdrop: false,
            isPermissions: isPermissions,
            isOpenDialogConfirmation: false
        };

        this.refFilter = React.createRef();
    }

    // инициализация страницы
    componentDidMount = () => {
        this.getListUsers();
    }

    // получение списка пользователя
    getListUsers = () => {
        this.setState({ isLoading: true });

        const filter = this.getFilter();

        axios('post', urls["user-get-list"], filter).then((response) => {
            const { data, headers } = response;

            let pagination = {...this.state.pagination};
            pagination.page = getPageFromCount(headers['x-pagination-total-count'], this.state.filter.limit);

            this.setState({
                rows: data || [],
                pagination: pagination,

                isLoading: false
            })
        });
    }

    // конвертация фильтров со фронта на бекенд
    getFilter = () => {
        return this.state.filter
    }

    // изменение фильтра
    onChangeFilter = (filter, isFastStart) => {
        this.setState({ filter }, () => {
            if (isFastStart) {
                this.getListUsers();
            }
        });
    }

    // функционал удаления пользователя (удаляется после подтверждения)
    onDeleteUser = (user = this.state.deleteUser, isConfirm) => {
        if (!isConfirm) {
            this.setState({
                deleteUser: user,
                isOpenDialogConfirmation: true
            });

            return null
        }

        this.setState({ isOpenDialogConfirmation: false, deleteUser: null, isShowBackdrop: true });

        axios('get', `${ urls['user-delete'] }/${ user.id }`).then(() => {
            this.getListUsers();
            sendMessages({
                message: "Пользователь успешно удален",
                type: "success"
            });

            this.setState({ isShowBackdrop: false })
        })
    }

    // функция перехода на страницы создания пользователя
    _routeCreateUser = () => {
        this.props.history.push('/users/created')
    }

    render() {
        const {
            rows, filter, pagination, isLoading,
            isOpenDialogConfirmation, deleteUser,
            isPermissions
        } = this.state;

        if (!isPermissions) {
            return (
                <WithoutAccess/>
            )
        }

        return (
            <Page>

                <Box mb={2}>
                    <Grid container spacing={2} justify="space-between">
                        <Grid item>
                            <Typography variant="h1">Пользователи</Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Создать пользователя">
                                <Button variant="contained" onClick={this._routeCreateUser}>Создать пользователя</Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={4} bgcolor="white" borderRadius={4} p={3}>
                    <Filter
                        filter={filter}
                        initialFilter={initialFilter}

                        onChange={this.onChangeFilter}
                        onSearch={this.getListUsers}
                    />
                </Box>

                <Box>
                    <Table
                        rows={rows}
                        filter={filter}
                        pagination={pagination}
                        isLoading={isLoading}

                        onChangeFilter={this.onChangeFilter}
                        onDeleteUser={this.onDeleteUser}
                    />
                </Box>

                <DialogConfirmation
                    title={`Удаление пользователя`}
                    comment={`Вы действительно хотите удалить пользователя "${ deleteUser?.full_name }"? После удаления результат необратим`}
                    buttonConfirmation="Удалить"

                    isOpen={isOpenDialogConfirmation}

                    onClose={() => this.setState({ isOpenDialogConfirmation: false })}
                    onConfirmation={() => this.onDeleteUser(deleteUser, true)}
                />

                <Backdrop open={this.state.isShowBackdrop} invisible={this.state.isShowBackdrop}>
                    <CircularProgress size={80} style={{color: 'white'}}/>
                </Backdrop>

            </Page>
        )
    }
};

export default UsersList
